<template>
    <!--    todo 删除行后,不能自动删除媒体资源-->
    <div class="AdvertCreate">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleSave" size="small" :disabled="disableSave">保存</el-button>
            <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="createForm"
            label-width="80px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-alert
                    title="1.文件名过长无法找到删除按钮时，可以使用DELETE键进行删除；2.如需重新上传文件，需要先把上传文件列表中的文件删除，然后再进行上传"
                    type="warning"
                    show-icon
                    center
                />
                <el-table
                    id="AdvertCreateTable"
                    border
                    style="width: 100%"
                    :data="form.tableData"
                    size="mini"
                    max-height="440"
                >
                    <el-table-column label="序号" type="index" width="50" align="center" />
                    <el-table-column label="广告名称" width="160">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.names'" :rules="rules.names">
                                <el-input v-model="scope.row.names" size="mini" :maxlength="50" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="客户名称" width="160">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'tableData.' + scope.$index + '.customerCodes'"
                                :rules="rules.customerCodes"
                            >
                                <el-select v-model="scope.row.customerCodes" size="mini" filterable>
                                    <el-option
                                        :value="c.code"
                                        :key="c.code"
                                        :label="c.name"
                                        v-for="c in meta.customers"
                                    />
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="广告类型" width="120">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.types'" :rules="rules.types">
                                <el-select
                                    v-model="scope.row.types"
                                    size="mini"
                                    @change="() => changeAdvertFileType(scope.row)"
                                >
                                    <el-option :value="imgFileType" label="图片" />
                                    <el-option :value="videoFileType" label="视频" />
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="文件上传" width="230">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'tableData.' + scope.$index + '.uploadAccessories'"
                                :rules="rules.uploadAccessories"
                            >
                                <!--用于校验提示-->
                                <el-input v-model="scope.row.uploadAccessories" style="display: none" />
                                <advert-upload
                                    :file-type="scope.row.types"
                                    v-model="scope.row.uploadFiles"
                                    :limit="1"
                                    @change="
                                        (uploadFileArr, uploadFileUrlArr) =>
                                            changeUploadFiles(uploadFileArr, uploadFileUrlArr, scope.row)
                                    "
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="跳转类型" width="150">
                        <template slot-scope="scope">
                            <el-form-item :prop="'tableData.' + scope.$index + '.jumpTypes'" style="width: 80%">
                                <el-select
                                    v-model="scope.row.jumpTypes"
                                    size="mini"
                                    :disabled="!scope.row.enableJumpTypes"
                                    @change="() => changeJumpType(scope.row)"
                                >
                                    <el-option label="不跳转" :value="1" />
                                    <el-option label="打开大图" :value="2" />
                                    <el-option label="跳转外链" :value="3" />
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="跳转内容" width="200">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'tableData.' + scope.$index + '.jumpLinks'"
                                style="width: 80%"
                                :rules="scope.row.jumpTypes === 1 ? [{ required: false }] : rules.jumpLinks"
                            >
                                <div v-if="scope.row.jumpTypes === 2">
                                    <el-input v-model="scope.row.jumpLinks" style="display: none" />
                                    <advert-upload
                                        :file-type="imgFileType"
                                        v-model="scope.row.jumpFiles"
                                        :limit="1"
                                        @change="
                                            (uploadFileArr, uploadFileUrlArr) =>
                                                changeJumpFiles(uploadFileArr, uploadFileUrlArr, scope.row)
                                        "
                                    />
                                </div>
                                <div v-else>
                                    <el-input
                                        :disabled="scope.row.jumpTypes === 1"
                                        v-model="scope.row.jumpLinks"
                                        :maxlength="200"
                                    />
                                </div>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="广告内容简介" width="320">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'tableData.' + scope.$index + '.contents'"
                                :rules="rules.contents"
                                style="width: 80%"
                            >
                                <el-input v-model="scope.row.contents" size="mini" :maxlength="500" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="广告时长(秒)" width="120">
                        <template slot-scope="scope"
                            ><el-form-item
                                :prop="'tableData.' + scope.$index + '.timeLengths'"
                                :rules="rules.timeLengths"
                            >
                                <el-select v-model="scope.row.timeLengths" size="mini">
                                    <el-option :value="15" label="15秒" />
                                    <el-option :value="30" label="30秒" />
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                :disabled="!scope.row.uploadAccessories"
                                @click="handlePreview(scope.row.types, scope.row.uploadAccessories)"
                                >预览</el-button
                            >
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <!--预览广告组件-->
        <advert-preview ref="advertPreview" />
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import AdvertUtils, { AdvertFileUploadUrl } from 'js/AdvertUtils';
import AdvertPreview from 'views/menu/advert/manage/AdvertPreview';
import AdvertUpload from 'views/menu/advert/manage/AdvertUpload';

export default {
    name: 'AdvertCreate',
    components: { AdvertPreview, AdvertUpload },
    data() {
        return {
            imgFileType: AdvertUtils.FileTypeEnum.IMG,
            videoFileType: AdvertUtils.FileTypeEnum.VIDEO,
            form: {
                tableData: [],
            },
            rules: {
                names: { required: true, message: '请填写广告名称', trigger: 'change' },
                customerCodes: { required: true, message: '请选择客户', trigger: 'change' },
                types: { required: true, message: '请选择广告类型', trigger: 'change' },
                uploadAccessories: { required: true, message: '请上传文件', trigger: ['change', 'blur'] },
                contents: { required: true, message: '请填写广告内容', trigger: 'change' },
                timeLengths: { required: true, message: '请选择广告时长', trigger: 'change' },
                jumpLinks: { required: true, message: '跳转内容不能为空', trigger: 'change' },
            },
            meta: {
                customers: [],
            },
            loading: false,
        };
    },
    mounted() {
        UrlUtils.QueryRemote(this, '/advert/customer/list', (rst) => {
            this.meta.customers = rst;
        });
    },
    computed: {
        //禁用/启用按钮
        disableSave() {
            return (this.form.tableData || []).length <= 0;
        },
    },
    methods: {
        changeAdvertFileType(row) {
            //切换广告文件类型，清空上传文件列表
            row.uploadFiles = [];
            row.uploadAccessories = '';
            // 切换为图片时 选择跳转类型为可用
            row.enableJumpTypes = row.types === this.imgFileType;
            // 切换为视频时清空跳转内容
            if (!row.enableJumpTypes) {
                row.jumpLinks = '';
            }
        },
        changeJumpType(row) {
            //切换跳转类型
            row.jumpLinks = '';
            row.jumpFiles = [];
        },
        changeUploadFiles(uploadFileArr = [], uploadFileUrlArr = [], row) {
            row.uploadFiles = uploadFileArr;
            //拼接url地址，实际上由于只会上传一个文件，对应的只生成一个url地址，所以拼接无影响
            row.uploadAccessories = uploadFileUrlArr
                .map((e = new AdvertFileUploadUrl()) => {
                    return e.advertFileUrl;
                })
                .join('');
        },
        changeJumpFiles(uploadFileArr = [], uploadFileUrlArr = [], row) {
            row.jumpFiles = uploadFileArr;
            //拼接url地址，实际上由于只会上传一个文件，对应的只生成一个url地址，所以拼接无影响
            row.jumpLinks = uploadFileUrlArr
                .map((e = new AdvertFileUploadUrl()) => {
                    return e.advertFileUrl;
                })
                .join('');
        },
        handleAdd() {
            this.form.tableData.push({
                names: '',
                customerCodes: '',
                types: 0,
                uploadAccessories: '',
                contents: '',
                timeLengths: 15,
                uploadFiles: [],
                jumpFiles: [],
                enableJumpTypes: true,
                jumpLinks: '',
                jumpTypes: 1,
            });
        },
        handlePreview(advertFileType, advertUrl) {
            this.$refs.advertPreview.showDialog(advertFileType, advertUrl);
        },
        handleDelete(advertIndex) {
            this.form.tableData.splice(advertIndex, 1);
        },
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid || this.disableSave) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                await this.$efApi.advertManageApi.create({
                    names: this.form.tableData.map((e) => e.names),
                    customerCodes: this.form.tableData.map((e) => e.customerCodes),
                    types: this.form.tableData.map((e) => e.types),
                    uploadAccessories: this.form.tableData.map((e) => e.uploadAccessories),
                    contents: this.form.tableData.map((e) => e.contents),
                    timeLengths: this.form.tableData.map((e) => e.timeLengths),
                    jumpTypes: this.form.tableData.map((e) => e.jumpTypes),
                    jumpLinks: this.form.tableData.map((e) => e.jumpLinks),
                });
                this.goBackAndReload();
            });
        },
    },
};
</script>
